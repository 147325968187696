/* src/index.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif; /* Solo Poppins */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background-color 0.3s ease, color 0.3s ease; /* Transición suave */
}

code {
  font-family: 'Source Code Pro', monospace; /* Solo Source Code Pro para el código */
}

* {
  font-family: 'Poppins', sans-serif; /* Solo Poppins */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}